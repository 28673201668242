import { Component, Input, ViewEncapsulation, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { TermBaseService } from '../../../services/term_base/termBase.service';
import { takeUntil } from 'rxjs/operators';
import { PageService } from '../../../services/common_service/page.service';
import { CountryLocatorService } from './country-locator.service';
import { Router } from '@angular/router';
import { CommonService } from '../../../services/common_service/common.service';
import { SelectedCCLC } from '../../../pages/contact/modules/contact-options/contact-options.model';
import { AppSelectorStore } from '../../../store/app.selector';
@Component({
  selector: 'app-country-locator',
  templateUrl: './country-locator.component.html',
  styleUrls: ['./country-locator.component.scss'],
  providers: [NgbModalConfig, NgbModal, CountryLocatorService],
  encapsulation: ViewEncapsulation.None
})

export class CountryLocatorComponent implements OnInit, OnDestroy {
  // Page related config
  pageConfig: any = this.pageService.config;

  // Translations
  allTranslations: any = {};

  // Feature configurations
  @Input() openCCLCAuto;
  @ViewChild('content', { static: true }) clcModalRef: ElementRef;
  @ViewChild('countrySelectorMobile', { static: true }) clcModalRefMobile: ElementRef;
  // Webcomponent specific inputs
  @Input() isWebCompActive = false;
  @Input() wcCC: String;
  @Input() wcLC: String;
  @Input() wcName: String;
  @Input() wcApiDomain: String;
  @Input() wcCountryOnCLickUrl: String;

  flagImg;
  
  // User information
  userData;
  userCountryData;

  destroySubject$: Subject<void> = new Subject();
  constructor(
    private countryLocatorService: CountryLocatorService,
    config: NgbModalConfig,
    private pageService: PageService,
    private modalService: NgbModal,
    private termService: TermBaseService,
    private router: Router,
    private commonService:CommonService,
    private renderer: Renderer2,
    private appSelectorStore:AppSelectorStore
  ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  open() {
    if(this.pageConfig.isMobile || this.pageConfig.isTablet) {
      this.modalService.open(this.clcModalRefMobile, { windowClass: 'clc-selector-mobile', size: 'sm' });
    } else {
      this.modalService.open(this.clcModalRef, { windowClass: 'country-selector', size: 'lg' , backdrop: true, keyboard: true});
      // backdrop: true - closes modal on clicking outside
      // keyboard: true - closes modal on clicking esc 
    }
  
  }

  accordianMobile(clikedIndex) {
    // Accordion Action
    const accordionItem = document.querySelectorAll(".accordion-item");
    let clickedItem = accordionItem[clikedIndex]
    let restItem = Array.from(accordionItem).filter((item) =>{
      if(!(item.classList.contains(clickedItem.classList[1]))) {
        return item;
      }
    });
    if (clickedItem.classList.contains('active')) {
        this.renderer.removeClass(clickedItem, "active");
      //  clickedItem.classList.remove("active");
    } 
    else {
      restItem.forEach((el) => this.renderer.removeClass(el, "active"));
      // restItem.forEach((el) => el.classList.remove("active"));
      this.renderer.addClass(clickedItem, "active");
      // clickedItem.classList.add('active')
    }

  }

  onClickCountry(event) {
    if (typeof sessionStorage !== "undefined" && this.router.url.indexOf("/warrantyresult/") > -1){
      const warrantyResultURL = sessionStorage?.getItem("previousUrl");
      if(warrantyResultURL){
        sessionStorage.setItem("previousUrlPresent", "true");
      }
    }
    const target = event.target || event.srcElement || event.currentTarget;
    const countryCode = target.getAttribute('rel');
    const languageCode = target.getAttribute('data-languageCode');
    let baseLoc = location.pathname.split('/');
    baseLoc = this.commonService.sanitizeUntrustedData(baseLoc);
    const clc = countryCode.toLowerCase() + '-' + languageCode.toLowerCase() ;
    baseLoc[1] = clc;
    let finalLoc;
    this.setContactOptionsCCLC(countryCode, languageCode);
    if (this.isWebCompActive) {
      const replaceObj = {
        '{cc}': this.wcCC,
        '{lc}': this.wcLC,
      };
      finalLoc = this.wcCountryOnCLickUrl.replace(/{cc}|{lc}/gi,
        function (matched) {
          return replaceObj[matched];
        });
    }
    else {
      finalLoc = baseLoc[2] !== 'search' 
      ? `${baseLoc.join('/')}${location.search}` 
      : `/${clc}`;


      let url = new URL(finalLoc, this.pageConfig.domain);
      let params = new URLSearchParams(url.search);
      params.delete('openCLC');
      if(url.pathname?.includes('check-warranty') && typeof location != 'undefined' && location?.hash?.includes('#multiple')) {
        return window.location.href = this.commonService.sanitizeUntrustedData(`${url.pathname}#multiple?${params.toString()}`);
      }
      return window.location.href = this.commonService.sanitizeUntrustedData(`${url.pathname}?${params.toString()}`);
    } 
    window.location.href = this.commonService.getSanitizedUrl(finalLoc);
  }
  
  showCountryFlag(showFlag, lc){ 
    return showFlag || lc==='uk';
  }

  ngOnInit() {
      this.countryLocatorService.getData(this.isWebCompActive, this.wcApiDomain, this.wcCC, this.wcLC)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(
          res => {
            this.userData = res.userData;
            this.userCountryData = res.userCountryData;
            if (this.openCCLCAuto && !this.commonService.checkIfBot()) {
              this.open();
            }
          }
        );
    if(this.isWebCompActive) {
      this.flagImg = `/wcc-assets/images/flags/flag_${this.wcCC}.gif`;
      const termBasePath = `${this.wcCC}-${this.wcLC}/${this.wcName}`;
      this.termService.fetchTermTranslationsForWC(this.wcApiDomain, termBasePath)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(
        (res: any) => {
          if (res.success && res.wcTranslations) {
            this.allTranslations = res.wcTranslations;
          }
        }
      );
    }
    else {
      this.pageService.getCurrentCountry().subscribe(() => {
        this.flagImg = `/wcc-assets/images/flags/flag_${this.pageConfig.cc}.gif`;
      });
      this.appSelectorStore.getAllTranslations$().pipe(takeUntil(this.destroySubject$)).subscribe(res=>this.allTranslations=res);
    }
    
  }

  setTitle(heading, countryName){
    return `${heading} ${countryName}`;
  }

  ngOnDestroy() {
    if(this.pageConfig.isBrowser){
      this.destroySubject$.next();    
    }
  }

  setClassIdName(regionName, selector){
    const americanReg = regionName.toLowerCase().includes("americas");
    const europianReg = regionName.toLowerCase().includes("europe");
    const asianReg = regionName.toLowerCase().includes("asia");
    const switchCaseType = {
      'ulId': () => {
        if(asianReg){return "euroList"}
      },
      'divId': () => {
        if(americanReg){return "dvAmerica"}
        if(europianReg){return "dvEmea_africa"}
        if(asianReg){return "dvAsia"}
      },
      "ulClass":()=>{
        //if(europianReg || americanReg){return "country-options"}
        return "country-options";
      }
    }
    return switchCaseType[selector]();
  }

  private setContactOptionsCCLC(countryCode, languageCode) {
    if (this.pageConfig?.isBrowser && location?.pathname?.split('/')?.[2] === 'contact') {
      const sessionData = this.commonService.safeParseJSON(localStorage?.getItem('sessionData'));
      if (sessionData?.subRegionCountryMap && !sessionData?.subRegionCountryMap?.[countryCode]) {
        const selectedCCLC: SelectedCCLC = { browser: { cc: countryCode, lc: languageCode }, contactOptions: { cc: countryCode, lc: languageCode } };
        sessionStorage.setItem('selectedCCLC', JSON.stringify(selectedCCLC));
      } else if(sessionData?.subRegionCountryMap?.[countryCode]) {
        sessionStorage?.setItem('subRegionCCLC', 'true');
      }
    }
  }

}

