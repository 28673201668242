<div class="sec-nav-wrapper {{pageConfig.languageDirection}}" [ngClass]="{'is-visid': pageConfig.isVisIdEnabled}">
	<div class="hp-section hp-header hp-desktop-header">
		<div class="nav-container">
			<div class="main-nav customDropdown" id="drop-down">
				<a id="mobileSearchTitle" class="dropdown-toggle mobile-nav-toggle hidden-lg" href="javascript:void(0)"
					data-toggle="customDropdown" (click)="showHideMenu()">
					{{secNavTitle}}<span [ngClass]="{'icon-dwn': !openMenu, 'icon-up': openMenu}"></span></a>
				<div class="dropdown-menu mobile-nav-wrapper">
					<div class="section-wrapper">
						<div class="container" id="nav-secondary">
							<ul>
								<ng-container *ngFor="let mainMenu of secNav">
									<li *ngIf="(enableDashboardLink|memoize:mainMenu:isUserAuthenticated) && mainMenu?.title" 
										(mouseenter)="mouseEnterEvent(mainMenu.title)" 
										(mouseleave)="mouseLeaveEvent()"
										(click)="toggleMenu(mainMenu.title)"
										(keypress.enter)="toggleMenu(mainMenu.title)"
										[class]="(getPagename|memoize:pageConfig.pageName) === mainMenu.activeLink?'active':''">
	
										<a *ngIf="mainMenu.elementId !== 'sec-nav-contact-support'" (click)="mainMenu.path===null || mainMenu.path===''?$event.preventDefault():''"
											[href]="commonService.getNavigationUrl|memoize:(mainMenu.path)"
											[id]="mainMenu?.elementId"
											class="submenu"
											title="{{mainMenu?.title}}">
											{{mainMenu.title}}
											<span *ngIf="mainMenu.childLinks && mainMenu.childLinks.length > 0"
												class="secnav-dropdown-carrot"
												[ngClass]="{ 'icon-dwn': dropdownOpen !== mainMenu.title,
																			'icon-up': dropdownOpen === mainMenu.title }">
											</span>
										</a>
										<a *ngIf="mainMenu.elementId === 'sec-nav-contact-support'" 
											href="javascript:void(0)"
											(click)="gotoContact()"
											[id]="mainMenu?.elementId"
											class="submenu"
											title="{{mainMenu?.title}}">
											{{mainMenu.title}}
											<span *ngIf="mainMenu.childLinks && mainMenu.childLinks.length > 0"
												class="secnav-dropdown-carrot"
												[ngClass]="{ 'icon-dwn': dropdownOpen !== mainMenu.title,
																			'icon-up': dropdownOpen === mainMenu.title }">
											</span>
										</a>
										
										<ul *ngIf="mainMenu.childLinks && mainMenu.childLinks.length > 0"
												[ngClass]="{'displayDropdownList': dropdownOpen === mainMenu.title}">
											<li *ngFor="let subMenu of mainMenu.childLinks">
												<a 	(click)="subMenu.elementId === 'sec-nav-dashboard1' ? goToDashboard($event, subMenu.childPath) : (subMenu.childPath === null || subMenu.childPath === '' ? $event.preventDefault() : '')"
													[href]="commonService.getNavigationUrl|memoize:(subMenu.childPath)" [id]="subMenu?.elementId"
													[target]="subMenu.newtab==='yes'?'_blank':'_self'"
													[ngClass]="{'login-required first-level': subMenu.additionalParameter == 'true'}">
													{{subMenu.childTitle}}</a>											
											</li>
											<span *ngIf="mainMenu?.additionalParameterText" class="login-reqd-message">*{{mainMenu.additionalParameterText}} </span>
										</ul>
	
									</li>
								</ng-container>
							</ul>
						</div>
					</div>
				</div>
	
			</div>
		</div>
	</div>
</div>
