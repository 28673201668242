import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Observer, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppState } from '../../../store/app.reducer';
import { isEmpty } from "lodash-es";
import { ConfigKeysModel } from '../../../services/ssc-store/sscKeys.reducer';
import { sscKeysSelector } from '../../../services/ssc-store/config.selectors';

// Already provided in Component
@Injectable()

export class HpHeaderService implements OnDestroy{
  destroySubject$: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>
  ) {}

 

  getHeaderSSC() {
    return new Observable((observer: Observer<any>) => {
      this.store.select(sscKeysSelector)
      .pipe(first(res=> !isEmpty(res)))
      .subscribe((sscKeys: ConfigKeysModel) => {
          const alertConditionA = sscKeys?.ALERT_DISABLED_TEMPLATE;
          const alertConditionB = sscKeys['ALERT_DISABLED_TEMPLATE']===undefined;
          const secnavConditionA = sscKeys?.SEC_NAV_DISABLED_TEMPLATE;
          const secnavConditionB = sscKeys['SEC_NAV_DISABLED_TEMPLATE']===undefined;
          const hideSearchTypeahead = sscKeys?.HEADER_EXPOSE_TYPEAHEAD === 'hide';
          const alertEnabled = this.getConditionalVal(alertConditionA,alertConditionB,'ALERT_DISABLED_TEMPLATE',sscKeys);
          const secNavEnabled = this.getConditionalVal(secnavConditionA,secnavConditionB,'SEC_NAV_DISABLED_TEMPLATE',sscKeys);
          observer.next({ success: true, alertEnabled, secNavEnabled, hideSearchTypeahead });
          observer.complete();
      });
    });
  }

  getConditionalVal(conditionA, conditionB, SSCKeyDesc, SSCres) {
    if (conditionA) return SSCres[SSCKeyDesc];
    if (conditionB) return '';
    return '';
  }

  getPrivacyBannerSSC() {
    return new Observable((observer : Observer<any>) => {
      this.store.select(sscKeysSelector)
      .pipe(first(res=> !isEmpty(res)))
      .subscribe((sscKeys: ConfigKeysModel) => {
          const privacyBannerUrl = sscKeys?.PRIVACY_BANNER_URL;
          const privacyBannerSupportedLocales = sscKeys?.PRIVACY_BANNER_ENABLED_LOCALES;
          observer.next({ success: true, privacyBannerUrl, privacyBannerSupportedLocales });
          observer.complete();
      });
    });
   }
  

  ngOnDestroy(): void {
    this.destroySubject$.next();
  }
}
