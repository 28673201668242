<!--Retired product Modal-->
<div class="retired-modal-block {{pageConfig.languageDirection}}">
  <div class="modal-header">
    <img class="modal-logo" alt="hp-logo" src="/wcc-assets/images/hp-logo-modal.gif">
    <h3 class="modal-title non-vis" id="retired-product-header">{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Heading"] }}</h3>
    <button type="button" class="close" aria-label="Close" (click)="closeErrorModal()">
      <span aria-hidden="true" id="retired-close-icon">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div  class='modal-body-wrapper'>
      <div class='modal-row mob-retired-prod-modal'>
        <div class='column logo'>
          <div class='status-column'>
            <div class='status-column-title'>
              <span>{{ allTranslations["#pps_WCC_FS_Dashboard_Status"] }}</span>
            </div>
            <img [src]="retiredProductData?.imageUrl"
                alt="{{ allTranslations['#pps_WCC_FS_Retired_Product_Modal_Column_1_Sub_Heading'] }}" />
            <p>{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Column_1_Sub_Heading"] }}</p>
          </div>
        </div>
        <div class='column details'>
          <div class='details-column'>
            <div class='status-column-title'>
              <span>{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Column_2_Heading"] }}</span>
            </div>
            <div class='status-column-sub-title'>
              <span>{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Column_3_Sub_Heading"] }}</span>
            </div>
            <div class='status-column-details'>
              <span>{{ allTranslations["#pps_WCC_FS_Retired_Product_Modal_Column_2_Details"] }}
              </span>
            </div>
          </div>
        </div>
        <div class='column mastiff' id="mastiff_retired_product_spos">
          <span class="svg-vault svg-spinner clockwise"></span>
        </div>
      </div>
    </div>
  </div>

  <app-retired-product-modal-footer></app-retired-product-modal-footer>
</div>