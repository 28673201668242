import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { PageService } from "../common_service/page.service";
import { HttpService } from "../service_loader/http.service";
import { FetchingSscKeysFailedAction, FetchingSscKeysSuccessAction, SscKeysActionTypes } from "./sscKeys.actions";
import { ConfigKeysModel } from "./sscKeys.reducer";
import { AppState } from "../../store/app.reducer";
import { Store } from "@ngrx/store";
import { isEmpty } from "lodash-es";
import { GlobalDataLayerService } from "../common_service/globalDataLayer.service";
import { CommonService } from "../common_service/common.service";
import { selectAllConfigKeys } from "./config.selectors";
@Injectable()
export class SscKeysEffects{

    pageConfig: any = this.pageService.config;
    loading = false;
    constructor(
        private actions: Actions,
        private http: HttpService,
        private pageService: PageService,
        private store : Store<AppState>,
        private commonService: CommonService,
        private gdlService: GlobalDataLayerService
    ){}
    sscKeysFetch = createEffect(()=>{
        return this.actions.pipe(
            ofType(SscKeysActionTypes.START_FETCHING_SSC_KEYS),
            concatLatestFrom(() => this.store.select(selectAllConfigKeys)),
            mergeMap(([sscKeyAction, configKeys]:[any, any]) => {
                const currentPage = sscKeyAction.payload.pageName || this.pageConfig.pageName;
                const currentPageKeys = configKeys[currentPage] || {};
              if((isEmpty(currentPageKeys?.ssc) || isEmpty(currentPageKeys?.udl))) {
                let sscUrlPath;
                if (sscKeyAction.payload.isWebCompActive) {
                  sscUrlPath = `${sscKeyAction.payload.wcApiDomain}/wcc-services/config/${sscKeyAction.payload.wcCC}-${sscKeyAction.payload.wcLC}/${'siteHome'}`
                }
                else {
                  sscUrlPath = `/wcc-services/config/${this.pageConfig.cc}-${this.pageConfig.lc}/${currentPage}`;
                }
                if(!this.loading) {
                  this.loading = true;
                  return this.http
                    .get(sscUrlPath)
                    .pipe(
                      map(response => {
                        this.loading = false;
                        return new FetchingSscKeysSuccessAction({
                          sscKeys: this.getConfigTransformed(response.ssc),
                          udlKeys: this.getConfigTransformed(response.udl)
                        }, currentPage);
                      }),
                      catchError(error => {
                        this.loading = false;
                        return of(new FetchingSscKeysFailedAction({}, currentPage));
                      })
                    )
                } else {
                  return of(new FetchingSscKeysFailedAction({}, currentPage));
                }
                
              } else {
                  return of(new FetchingSscKeysSuccessAction({
                    sscKeys: currentPageKeys?.ssc,
                    udlKeys: currentPageKeys?.udl
                  }, currentPage));
                }
               
            })
        );
    });

    getConfigTransformed(resp:[{key:string, value:string}]):ConfigKeysModel{
      const tKeys:ConfigKeysModel = {};
      const updateGDLMedalliaLc = (cclcMapping: any) => {
        const parsed = this.commonService.safeParseJSON(cclcMapping);
        this.gdlService.updateGDL({medalliaLC: parsed?.[this.pageConfig.locale] || ''}, true)
      }

      if(resp.length){
        for(const item of resp){
          if (this.pageConfig.isBrowser && item?.key === 'MEDALLIA_CCLC_MAPPING') {
            updateGDLMedalliaLc(item?.value);
          }

          tKeys[item.key] = item.value
        }
      }
      return tKeys;
    }
     
}