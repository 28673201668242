import { Injectable, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LinkService } from '../../services/seo-service/link.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isPlatformBrowser, Location } from '@angular/common';
import { WindowRefService } from '../window/window.service';
import { HttpService } from '../service_loader/http.service';
import { userAgentConfig } from '../../config/user-agent.config';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';


export interface RouteDataModal{
  params: {[key:string]:string},
  data: {[key:string]:string},
  queryParams: {[key:string]:string}
}

// Root provider is required
@Injectable({
  providedIn: 'root'
})

/**
 * The config object in this service will hold the below information 
 * 
 * cc                   - Country code
 * lc                   - Language code
 * countryLanguageCode  - HP.com country/language code
 * locale               - cc-lc
 * unknownCCLC          - Will be set to true if openClc query param is true
 * language             - Route's :lang param (eg. us-en)
 * languageDirection    - Set based on cc-lc
 * domain               - From common service - window.location.origin
 * templateName         - Route's templateName
 * pageName             - From router's data
 * userAgent            - From DeviceDetectorService
 * os                   - Operating system
 * isBrowser            - From isPlatformBrowser
 * isMobile             - From DeviceDetectorService
 * isTablet             - From DeviceDetectorService
 * isDesktop            - From DeviceDetectorService
 * isiPhone             - From DeviceDetectorService
 * isiPad               - From DeviceDetectorService
 * queryParams          - Query params object
 * routeParams          - Route params object
 * routeData            - Route data object
 * isHandHeld           - any mobile device
 */

export class PageService implements OnDestroy {
  config: any = {
    languageDirection: 'ltr',
    unknownCCLC: false,
    isNavAuthenticated: false,
    noCCLCAllowed: false,
    isVisIdEnabled: null
  };
  
  private window: Window;

  destroySubject$: Subject<void> = new Subject();
  currentCountryInfo = new BehaviorSubject<any>({});

  private routeDataSub$:BehaviorSubject<RouteDataModal> = new BehaviorSubject<RouteDataModal>({
    params: {},
    data: {},
    queryParams: {}
  });

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private deviceService: DeviceDetectorService,
    private linkService: LinkService,
    private windowRef: WindowRefService,
    private http: HttpService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.window = this.windowRef.nativeWindow;
    this.config.isBrowser = isPlatformBrowser(this.platformId);
    this.config.isMobile = this.deviceService.isMobile();
    this.config.isTablet = this.deviceService.isTablet();
    this.config.isDesktop = this.deviceService.isDesktop();
    this.config.userAgent = this.deviceService.userAgent && this.deviceService.userAgent.toLocaleLowerCase()!=='unknown' ? this.deviceService.userAgent: this.getUserNavigatorDetails().navigator?.userAgent;

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route:ActivatedRouteSnapshot):RouteDataModal => {

          let params:{[key:string]:string} = {...route?.params};
          let data:{[key:string]:string} = {...route?.data};
          let queryParams:{[key:string]:string} = {...route?.queryParams};

          while (route.firstChild) {
            route = route.firstChild;

            params = {...params, ...route?.params};
            data = {...data, ...route?.data};
            queryParams = {...queryParams, ...route?.queryParams};
          }

          return {params, data, queryParams};
        })
      ).subscribe(resp=>{
        this.routeDataSub$.next(resp);
      });
  }

  getDataFromQueryParams(queryParams) {
    this.config.queryParams = queryParams;

    if (queryParams && queryParams.openCLC) {
      this.config.unknownCCLC = true;
    }

    if (queryParams && queryParams.originid && typeof sessionStorage !== 'undefined') {
      sessionStorage?.setItem('contactOriginId', queryParams.originid);
    }

    this.config.originId = queryParams.originid || null;
  }

  loadCountrySpecificFontsAndStyles(canonicalUrl) {
    this.addLinkTag(canonicalUrl);
  }

  getRoutedataSub():Observable<RouteDataModal>{
    return this.routeDataSub$.asObservable();
  }

  getDataFromRouteParams(routeParams) {
    const params = routeParams;
    this.config.routeParams = params;

    this.config.language = params['lang'];
    this.config.cc = this.getCcLc('cc');
    this.config.lc = this.getCcLc('lc');
    this.config.locale = this.config.cc + '-' + this.config.lc;
    this.config.countryLanguageCode = this.getHpDotComCountrylangCode(this.config.cc, this.config.lc);

    this.checkDirection();
    this.setDocumentLanguage();
  }

  setDocumentLanguage() {
    if (this.config.isBrowser) {
      document.documentElement.lang = this.config.lc;
    }
  }

  getDataFromRouteData(routeData) {
    this.config.routeData = routeData;
    this.config.pageName = routeData.pageName;
    this.config.templateName = routeData.templateName;
  }

  // This method is called from AppLayoutComponent to initialize all page level configurations
  init(data: any) {
    this.config.os = this.deviceService.os.toLocaleLowerCase()!=='unknown'?this.deviceService.os:this.getUserNavigatorDetails().os.name;
    this.config.browser = this.deviceService.browser.toLocaleLowerCase()!== 'unknown' ?this.deviceService.browser:this.getUserNavigatorDetails().browser.name;
    this.config.domain = this.getDomain();
    this.config.isiPhone = this.isiPhone();
    this.config.isiPad = this.isiPad();
    this.config.isHandHeld = this.config.isMobile || this.config.isiPad ||  this.config.isiPhone;
    this.config.isServicePage = data.isServicePage;
    this.getDataFromRouteParams(data.routeParams);
    this.getDataFromRouteData(data.routeData);
    this.getDataFromQueryParams(data.queryParams);
  }

  // Moved from CommonService

  getDomain() {
    // return typeof this.window !== 'undefined' ? this.window.location?.origin : '';
    return this.config.isBrowser ? this.window.location?.origin : 'https://support.hp.com';
  }

  isiPhone() {
    const currentDeviceInfo = this.deviceService.getDeviceInfo();
    const userAgent = currentDeviceInfo?.userAgent;

    if ((/iPhone/g).test(userAgent)) {
      if (navigator && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) {
        return true;
      }
    }

    return false;
  }

  isiPad() {
    const currentDeviceInfo = this.deviceService.getDeviceInfo();
    const userAgent = currentDeviceInfo.userAgent;

    if ((/iPad/g).test(userAgent) || (/Macintosh/g).test(userAgent)) {
      if (navigator && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) {
        return true;
      }
    }

    return false;
  }

  getCcLc(cclc) {
    const params = this.config.routeParams;

    if (params && params.lang) {
      const retVal = cclc.trim().toLowerCase() === 'cc'
                      ? params.lang.split('-')[0]
                      : params.lang.split('-')[1];

      return retVal;
    } else if (typeof this.location !== 'undefined') {
      let pathname = this.location.path().split('?')[0];
      return cclc.trim().toLowerCase() === 'cc'
              ? pathname.split('/')[1].split('-')[0]
              : pathname.split('/')[1].split('-')[1];
    } else {
      return cclc.trim().toLowerCase() === 'cc' ? 'us' : 'en';
    }
  }

  addLinkTag(canonicalUrl) {
    const linkService = this.linkService;
    // load canonical link
    if (!this.config.isBrowser || !(this.window?.navigator?.userAgent.indexOf('Googlebot') > -1)) {
      linkService.addTag({ rel: 'canonical', href: canonicalUrl });
    }
    
  }

  // createPortalJSessionId() {
  //   if (this.config.isBrowser && this.getCookie('JSESSIONID') === null) {
  //     // do cookie doesn't exist stuff;
  //     const cc = this.config.cc;
  //     const lc = this.config.lc;

  //     this.http.get(`/${cc}-${lc}/document/c05061199`, { responseType: 'text' }, true)
  //       .subscribe(
  //         (res: any) => {
  //           // console.log(res, 'res from createPortalJSessionId')
  //         }
  //       );
  // }
    
  // }

  checkDirection() {
    /* UNCOMMENT TO ENABLE RTL FOR HEBREW AND ARABIC LANGUAGES */
    if ((this.config.lc === 'ar') || (this.config.lc === 'he')) {
      this.config.languageDirection = 'rtl';
    }
  }

  getHpDotComCountrylangCode(cc, lc) {
    let countryLangCode = `${cc}/${lc}`;
    switch (countryLangCode) {
      case 'gb/en':
        countryLangCode = 'uk/en';
        break;
      case 'bg/en':
        countryLangCode = 'bg/bg';
        break;
      case 'hr/en':
        countryLangCode = 'hr/hr';
        break;
      case 'ee/en':
        countryLangCode = 'ee/et';
        break;
      case 'lv/en':
        countryLangCode = 'lv/lv';
        break;
      case 'lt/en':
        countryLangCode = 'lt/lt';
        break;
      case 'ro/en':
        countryLangCode = 'ro/ro';
        break;
      case 'rs/en':
        countryLangCode = 'rs/sr';
        break;
      case 'sk/en':
        countryLangCode = 'sk/sk';
        break;
      case 'si/en':
        countryLangCode = 'si/sl';
        break;
      default:
        countryLangCode = `${cc}/${lc}`;
    }
    return countryLangCode;
  }

  getCurrentCountry() {
    return this.currentCountryInfo.asObservable();
  }

  setCurrentCountry(country) {
    this.currentCountryInfo.next(country);
  }

  loadMastiffJs(mastiffUrl, callback=()=>{}) {
    if (typeof window !== 'undefined') {
      const mastiffScript = document.getElementById('mastiff');

      if (mastiffScript && window['initiateMastiffCall']) {
        window['initiateMastiffCall']();
        callback();
        return;
      }
    }

    const setAttributes = function(el, attrs) {
      for(var key in attrs) {
        el.setAttribute(key, attrs[key]);
      }
    };

    const loadScript = function(url) {
      if(url && typeof document!=='undefined'){
        const js = document?.createElement("script");
        setAttributes(js, {'src': url, 'defer' : '', 'id':'mastiff'});
        js.addEventListener('load', callback);
        document?.body?.appendChild(js);
        return js;
      }
    };

    if(typeof window !== 'undefined' && typeof document!=='undefined'){
      var elem = document?.getElementById("mastiff");
      if(elem){
        elem.remove();
        loadScript(mastiffUrl)
      }else {loadScript(mastiffUrl)}
      
    }
  }

//   getCookie(name) {
//     var dc = document?.cookie;
//     var prefix = name + "=";
//     var begin = dc.indexOf("; " + prefix);
//     if (begin == -1) {
//         begin = dc.indexOf(prefix);
//         if (begin != 0) return null;
//     }
//     else
//     {
//         begin += 2;
//         var end = document?.cookie.indexOf(";", begin);
//         if (end == -1) {
//         end = dc.length;
//         }
//     }
    
//     return decodeURI(dc.substring(begin + prefix.length, end));
// } 

  getUserNavigatorDetails() {
    const init = () => {
      if (typeof window !== 'undefined') {
        const header = [ window.navigator?.platform, window.navigator?.userAgent, window.navigator?.appVersion, window.navigator?.vendor ];

        let agent = header.join(' '),
            os = matchItem(agent, userAgentConfig.dataos),
            browser = matchItem(agent, userAgentConfig.databrowser);

        return { os: os, browser: browser, navigator: window.navigator };
      }

      return userAgentConfig.default;
    }
    
    const matchItem = (string, data) => {
      let i = 0,
        j = 0,
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
          matches = string.match(regexv);
          version = '';
          if (matches) { if (matches[1]) { matches = matches[1]; } }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.';
              } else {
                version += matches[j];
              }
            }
          } else {
            version = '0';
          }
          return {
            name: data[i].name,
            version: parseFloat(version)
          };
        }
      }
      return { name: 'unknown', version: 0 };
    }

    return init();
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }
}
