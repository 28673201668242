import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'lodash-es';
import { HttpService } from '../services/service_loader/http.service';
import { first, map, takeUntil } from 'rxjs/operators';
import { PageService } from '../services/common_service/page.service';
import { CommonService } from '../services/common_service/common.service';
import { Store } from "@ngrx/store";
import { AppState } from "../store/app.reducer";
import { sscKeysSelector } from '../services/ssc-store/config.selectors';
import { ConfigKeysModel } from '../services/ssc-store/sscKeys.reducer';
import { LoadRegions } from './regions/regions.actions';
import { getAllRegions } from './regions/regions.selectors';
import { Region } from '../services/regions.service';

@Injectable({
  providedIn: 'root'
})

export class SSCStore implements OnDestroy {
  // Page related config
  pageConfig: any = this.pageService.config;

  // SSC related config
  sscKeys = new BehaviorSubject<any>({ sscKeys: null });
  sscKeys$ = this.sscKeys.asObservable();

  // Global SSC related config
  globalSscKeys = new BehaviorSubject<any>({ globalSscKeys: null });
  globalSscKeys$ = this.globalSscKeys.asObservable();

  // SSC Region BehaviourSubject
  sscRegionData = new BehaviorSubject<any>({});

  destroySubject$: Subject<void> = new Subject();

  constructor(
    private commonService: CommonService,
    private pageService: PageService,
    private http: HttpService,
    private store: Store<AppState>
  ) {}

  // not in use anymore
  fetchSSCKeys(pageName = null, isWebCompActive=false, wcCC=null, wcLC=null, wcApiDomain=null) {
    const currentPage = pageName || this.pageConfig.pageName;
    let sscUrlPath;
    if (isWebCompActive) {
      sscUrlPath = `${wcApiDomain}/wcc-services/ssc/config/${wcCC}-${wcLC}/${'siteHome'}`
    }
    else {
      sscUrlPath = `/wcc-services/ssc/config/${this.pageConfig.cc}-${this.pageConfig.lc}/${currentPage}`;
    }
    this.http
      .get(sscUrlPath)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: any) => {
        if (res && res.data) {
          this.sscKeys.next({ sscKeys: res.data });
        }
      });
  }

  // not in use anymore
  getSSCKeys(pageName = null) {
    const currentPage = pageName || this.pageConfig.pageName;

    return this.http
      .get(`/wcc-services/ssc/config/${this.pageConfig.cc}-${this.pageConfig.lc}/${currentPage}`)
      .pipe(
        map(res => {
          if (res && res.data) {
            const result = { sscKeys: res.data };
            this.sscKeys.next(result);
            return result;
          }
        })
      );
  }

  // This functions is not in use in application
  getGlobalconfigKeys() {
    this.http
      .get(`/wcc-services/ssc/config/${this.pageConfig.cc}-${this.pageConfig.lc}/global`)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: any) => {
        if (res && res.data) {
          this.globalSscKeys.next({ globalSscKeys: res.data });
        }
      });
  }

  getHpDotComCcLcMapping() {
    this.store.dispatch(new LoadRegions());
    this.store.select(getAllRegions)
    .pipe(takeUntil(this.destroySubject$))
    .subscribe( (regions: Region[]) => {
      if (regions?.length) {
          
        this.sscRegionData.next(regions);

        const cc = this.pageConfig.cc;
        const lc = this.pageConfig.lc;

        const isCountryPresent = filter(regions, function (o) {
          return o.cclc === `${cc}-${lc}`;
        });

        if (isCountryPresent.length) {
          this.hpLocaleMapping();
        } else {
          //const defaultUrl = this.pageConfig.pageName !=='contact'?`us-en?openCLC=true`:'';
          if (typeof window !== 'undefined') {
            let url = new URL(location.href);
            let params = new URLSearchParams(url.search);

            params.append('openCLC', 'true');
            
            let contactUrl: any = url.pathname;
            contactUrl = contactUrl.split('/');
            contactUrl[1] = 'us-en';
            
            let resolvedUrl = this.pageConfig.pageName !== 'contact'
            ? `${this.pageConfig.domain}/us-en?openCLC=true`
            : `${this.pageConfig.domain}${contactUrl.join('/')}?${params.toString()}`;
            resolvedUrl = this.commonService.getSanitizedUrl(resolvedUrl);
            
            if (!this.pageConfig.noCCLCAllowed) {
              window.location.href = resolvedUrl;
            } 
          }
        }
      } else {
        this.hpLocaleMapping();
      }
    })
  }

  hpLocaleMapping() {

    this.store.select(sscKeysSelector).pipe(
      first(sscKeys=> !!sscKeys?.HP_COM_LOCALES_MAPPING)
    ).subscribe((sscKeys: ConfigKeysModel) => {
          const parsedConfigData = this.commonService.safeParseJSON(sscKeys.HP_COM_LOCALES_MAPPING);
          this.commonService.hpDotComCcLcMappingSub.next(parsedConfigData);
    });

  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }
}